export const STATUSES = [
  {
    title: 'очікує обробки',
    value: 'pending',
    color: 'red'
  },
  {
    title: 'в процесі',
    value: 'in_progress',
    color: 'orange'
  },
  {
    title: 'дані відправлені психологу',
    value: 'sent_to_expert',
    color: 'blue'
  },
  {
    title: 'візит узгоджено',
    value: 'visit_agreed',
    color: 'violet',
  },
  {
    title: 'візит відбувся',
    value: 'visited_expert',
    color: 'green',
  },
  {
    title: 'не валідний',
    value: 'not_valid',
    color: 'grey'
  }
 ] as const;