import { SESSION_TYPES } from './constants/sessionTypes';
import { THEMES } from './constants/themes';
import { DETAILED_TYPES } from './constants/detailedTypes';
import { STATUSES } from  './constants/statusTypes';

export function compose(...funcs: ((args?: any) => any)[]) {
  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}

export const normalizePhone = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/^\+\d{2}/g, "").replace(/[^\d]/g, "");
  if (onlyNums.length === 0) {
    return "";
  }
  if (onlyNums.length <= 3) {
    return `+38 (${onlyNums}`;
  }
  if (onlyNums.length <= 7) {
    return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 9) {
    return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(
      3,
      6
    )}-${onlyNums.slice(6)}`;
  }
  return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(
    3,
    6
  )}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`;
};

export const typePrice = ({ online, offline }:{ online?:number, offline?:number }) => 
  online && offline
    ? "Онлайн та особисто"
    : online
      ? "Тільки онлайн"
      : offline
        ? "Тільки особисто"
        : null;

export const renderPrice = ({ online, offline }:{ online?:number, offline?:number }) => 
  online === offline
    ? `${online} грн. `
    : online && offline
      ? `${online} грн. / ${offline} грн. `
      : online
        ? `${online} грн. `
        : offline && `${offline} грн . `;

export const scrollWidthOffset = (el:any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const sessionTypeTransform = (type: string) => {
  return (SESSION_TYPES as any)[type];
};

export const detailedValueTransform = (detailed: string) => {
  return (DETAILED_TYPES as any)[detailed];
};

export const testResultsTransform = (theme: any, detailed: any) => {
  const retTestResults = theme.map((theme:any) => {
    let retObj = {
      title: '',
      detailed: [],
    };
    const currentTheme: any = THEMES.find((item: any) => item.id === theme);

    retObj['title'] = currentTheme.title;

    if (detailed) {
      retObj['detailed'] = currentTheme.detailed.map((item:any) => {
      let retDetailedObj = {
        title: item.title,
        value: '',
      }

      retDetailedObj['value'] = detailedValueTransform(detailed[currentTheme.id][item.id] && detailed[currentTheme.id][item.id]);

      return retDetailedObj; 
      });
    }
    return retObj;
  });
  return retTestResults;
};

export const testResultsToString = (theme: any, detailed: any) => {
  const results = testResultsTransform(theme, detailed);
  return results;
};

export const currentStatus = (status: string) => {
  return STATUSES.filter(stat => stat.value === status)[0];
};

export const transformToMinPrefix = (value: string): string => {
  const newValue = value.split('.');

  newValue.splice(-1, 0, 'min');

  return newValue.join('.');
}
