import { prefix } from './actions';
import { IRootState } from '../reducer';

export const loading = (state: IRootState) => state[prefix].loading;

export const error = (state: IRootState) => state[prefix].error;

export const contactExperts = (state: IRootState) => state[prefix].contactExperts;

export const currentContactExpert = (state: IRootState) => state[prefix].currentContactExpert;

export const updatingStatus = (state: IRootState) => state[prefix].updatingStatus;