import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getStat } from '../../redux/stat/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, stat } from '../../redux/stat/selectors';
import Stat from './Stat';


const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  stat,
});

const actions = {
  getStat,
};

const reduxConect = connect(selector, actions);


export { Stat };

export default reduxConect(Stat);