import React, { useEffect } from 'react';
import classes from './Stat.module.scss';

interface IProps {
  getStat(): void;
  stat?: {
    allUsersCount: number,
    autotestUsersCount: number,
    manualRequestsCount: number,
    contactExpertCount: number,
    expertsCount: number,
  }
};

const Stat: React.FC<IProps> = (props) => {
  const { getStat, stat } = props;

  useEffect(() => {
    getStat && getStat();
  },[])
  return (
      <div className={classes.main}>
        <div className={classes.element}>
          Всього користувачів: {stat && stat.allUsersCount}
        </div>
        <div className={classes.element}>
          Всього експертів: {stat && stat.expertsCount}
        </div>
        <div className={classes.element}>
          Пройдено автотестів: {stat && stat.autotestUsersCount}
        </div>
        <div className={classes.element}>
          Всього ручних запитів до психолога: {stat && stat.manualRequestsCount}
        </div>
        <div className={classes.element}>
          Кількість контактів з психологом: {stat && stat.contactExpertCount}
        </div>
      </div>
  )
};

export default Stat;