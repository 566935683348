import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { compose } from '../../utils';

import { getManualRequest, changeStatus } from '../../redux/manualRequests/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, currentManualRequest, updatingStatus } from '../../redux/manualRequests/selectors';
import CurrentManualRequest from './CurrentManualRequest';

const formConect = reduxForm<{ status: string }, {}>({
  form: 'manualStatus',
});

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  currentManualRequest,
  updatingStatus,
});

const actions = {
  getManualRequest,
  onSubmit: changeStatus
};

const reduxConect = connect(selector, actions);


export { CurrentManualRequest };

export default compose(reduxConect, formConect)(withRouter(CurrentManualRequest));