import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IExpertState {
  loading: boolean;
  error: object | null;
  expert: object;
  experts: {
    profile: {
      firstName: string;
      photoUrl: string;
    };
    therapy: {
      price: {
        online?: number;
        offline?: number;
      }
    };
    isHidden: boolean;
    expertId: string;
  }[];
}

const initExpertState = {
  expertId: '',
  profile: {
    firstName: '',
    videoUrl: '',
    photoUrl: '',
    about: '',
    shortDescription: '',
  },
  education: {
    basic: [],
    extra: [],
  },
  experience: {
    years: null,
    diplomas: [],
    infoText: null,
  },
  therapy: {
    price: {
      online: null,
      offline: null
    },
    themeBadges: [],
    languages: [],
    address: '',
  },
};

export default combineReducers<IExpertState>({
  loading(state: boolean = false, action) {
    switch (action.type) {
      case actions.GET_ALL_EXPERTS_REQUEST:
      case actions.GET_EXPERTS_BY_IDS_REQUEST:
        return true;
      case actions.GET_ALL_EXPERTS_SUCCESS:
      case actions.GET_EXPERTS_BY_IDS_SUCCESS:
      case actions.GET_ALL_EXPERTS_FAILURE:
      case actions.GET_EXPERTS_BY_IDS_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.GET_ALL_EXPERTS_FAILURE:
        return action.error || null;
      default:
        return state;
    }
  },  
  experts(state: any[] = [], action) {
    switch (action.type) {
      case actions.GET_ALL_EXPERTS_SUCCESS:
      case actions.GET_EXPERTS_BY_IDS_SUCCESS:
        return action.data;
      case actions.GET_ALL_EXPERTS_FAILURE:
      case actions.GET_EXPERTS_BY_IDS_FAILURE:
        return [];
      case actions.PATCH_EXPERT_SUCCESS:
        return state.map((item: any) => {
          if (item._id === action.data._id) {
            return action.data
          }
          return  item
        })
      case actions.PATCH_EXPERT_FAILURE:
        return state
      default:
        return state;
    }
  },
  expert(state: object = initExpertState, action) {
    switch (action.type) {
      case actions.GET_EXPERT_SUCCESS:
        return action.data;
      case actions.GET_EXPERT_FAILURE:
        return {};
      default:
        return state;
    }
  },
});
