import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '../../redux/reducer';

import PrivateRoute, { IProps } from './PrivateRoute';
import { isAuthenticated, loading } from '../../redux/profile/selectors';

interface OwnProps {
  path: string;
  exact?: boolean;
  component: any;
}

const selector = createStructuredSelector<IRootState, OwnProps, Partial<IProps>>({
  isAuthenticated,
  loading,
});

export default withRouter<RouteComponentProps & OwnProps, any>(
  connect(
    selector,
    null,
    null,
    { pure: false },
  )(PrivateRoute),
);