import { all } from 'redux-saga/effects';
import { History } from 'history';

import { IApi } from '../api';
import { loginSuccess, logoutSuccess, loginFailure } from "./profile/sagas";

export default function* rootSaga(history: History, api: IApi) {
  yield all([
    loginSuccess(history, api),
    logoutSuccess(history, api),
    loginFailure(history, api),
  ]);
}
