import { prefix } from './actions';
import { IRootState } from '../reducer';

export const loading = (state: IRootState) => state[prefix].loading;

export const error = (state: IRootState) => state[prefix].error;

export const manualRequests = (state: IRootState) => state[prefix].manualRequests;

export const currentManualRequest = (state: IRootState) => state[prefix].currentManualRequest;

export const updatingStatus = (state: IRootState) => state[prefix].updatingStatus;