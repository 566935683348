import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import NavBar from './components/Navbar';
import { ROUTES } from './constants/routes';

const Routes = () => (
  <Switch>
    <Route path={ROUTES.LOGIN} component={LoginPage} />
    <NavBar />
  </Switch>
);

export default Routes;
