import React, { useEffect } from 'react';
import PaginationTable from '../PaginationTable';
import { ROUTES } from '../../constants/routes';

interface IProps {
  getList?(): void;
  manualRequests?: {
    _id: string,
    status: string,
    userId: string,
    createdAt: string,
    updatedAt?: string,
    userRequest: {
      type: string,
      manual: {
        request: string,
        specialistRequest?: string,
      }
    },
    age?: string,
    sex?: string,
    email: string,
    name: string,
    phone: string,
    connectType: string,
  }[];
}

const ManualRequests: React.FC<IProps> = (props: any): JSX.Element => {
  const { getList, manualRequests } = props;

  useEffect(() => {
    getList && getList();
  },[])
  return (
    <PaginationTable
      data={manualRequests}
      route={ROUTES.MANUAL_REQUESTS}
      header='Запити ручного підбору психолога'
    />
  );
};

export default ManualRequests;