import React, { useEffect } from 'react';
import { Row, Col, Card, Container, Button, Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import FieldInput from '../Input';
import { sessionTypeTransform, testResultsTransform, testResultsToString, currentStatus } from '../../utils';
import { STATUSES } from '../../constants/statusTypes';

interface IProps {
  getContactExpert?(): void;
  updatingStatus: boolean;
  currentContactExpert?: {
    connectType: string,
    telegramName?: string,
    createdAt: string,
    updatedAt?: string,
    userRequest?: {
      theme?: string[],
      detailed?: object,
      type: string,
      manual?: {
        request: string,
        specialistRequest?: string,
      }
    },
    email: string,
    expertEmail: string,
    comment?: string,
    // expertId: string,
    expertLastName: string,
    expertName: string,
    expertPhone: string,
    expertSocialLinks?: string[],
    name: string,
    phone: string,
    age?: string,
    sex?: string,
    status: string,
    _id: string,
  };
  onSubmit(): void;
}

const CurrentContactExpert: React.FC<IProps & any> = (props): JSX.Element => {
  const { getContactExpert, currentContactExpert, onSubmit, handleSubmit, updatingStatus, match } = props;

  useEffect(() => {
    const contactId = match.params.id;
    getContactExpert && getContactExpert(contactId);
  }, []);

  const copyToClipboard = (data: any) => {
    const copiedData = 
    `Ім'я: ${data.name}
    Телефон: ${data.phone}
    Email: ${data.email}
    Вік: ${data.age || 'N/A'}
    Стать: ${data.sex || 'N/A'}
    Тип зв'язку: ${data.connectType}
    Telegram username: ${data.connectType === 'telegram' ? data.telegramName : 'N/A'} 
    Тип конусльтації: ${sessionTypeTransform(data.userRequest.type) || 'N/A'}
    Коментар: ${data.comment || 'N/A'}\n
    Результати aвтотесту: 
      ${testResultsToString(
        data.userRequest && data.userRequest.theme,
        data.userRequest && data.userRequest.detailed
      ).map((item: any, i: number) => `\n${i+1}. ${item.title}: ${item.detailed.map((detailed: any) => `\n\t• ${detailed.title}: ${detailed.value}`).join(' ')}`).join(' ') || 'N/A'}
    \nЗапит ручного підбору: \n\tОсновний текст: \n\t • ${data.userRequest.manual.request || 'N/A'} \n\n\t Побажання щодо спеціаліста при ручному підборі(якщо є):\n\t• ${data.userRequest.manual.specialistRequest || 'N/A'}`
    navigator.clipboard.writeText(copiedData);
  };

  const renderAutotestResults = (userRequest: any) => {
    const trData = testResultsTransform(
      userRequest && userRequest.theme,
      userRequest && userRequest.detailed
    );
    return trData.map((item: any) => (
      <>
        <Row className='ml-3'>
          <Col>
            <span className='bold'>{item.title}:</span>
            {item.detailed.map((detailed: any) => (
              <li className='ml-3'>
                {detailed.title}: {detailed.value}
              </li>
            ))}
          </Col>
        </Row>
      </>
    ));
  };

  const renderManualResults = (UserRequest: any) => {
    return (
      <>
        <Row className='ml-3'>
          <Col>
            <span className='bold'>Що турбує: </span>{UserRequest.manual.request || 'N/A'}
          </Col>
        </Row>
        <Row className='ml-3'>
          <Col>
            <span className='bold'>Побажання щодо підбору психолога: </span> {UserRequest.manual.specialistRequest || 'N/A'}
          </Col>
        </Row>
      </>
    );
  };

  const submit = (values: any) => {
    const data: { _id: string, status: string } = {
      status: values.status,
      _id: match.params.id
    };

    onSubmit(data);
  };

  const {
    connectType,
    telegramName,
    createdAt,
    updatedAt,
    email,
    expertEmail,
    expertLastName,
    expertName,
    expertPhone,
    expertSocialLinks,
    name,
    phone,
    status,
    userRequest,
    comment,
    age,
    sex,
  } = currentContactExpert;

  const reqCurrentStatus = currentStatus(status);
  const backToTable = match.path.replace("/:id", "");

  return (
    <Container>
      <Row className='mt-4'>
        <Col>
          <Link to={backToTable ? backToTable : "/"}>
            <Button>Назад до таблиці</Button>
          </Link>
        </Col>
      </Row>

      <Card className='my-4 p-3'>
        <Row className='text-center'>
          <Col>
            <h2>Запит до психолога</h2>
          </Col>
        </Row>
        <Row>
          <Col>Створено: {moment(createdAt).format("DD/MM/YYYY HH:mm")}</Col>
        </Row>
        <Row>
          <Col>
            Оновлено:{" "}
            {updatedAt ? moment(updatedAt).format("DD/MM/YYYY HH:mm") : "N/A"}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            Статус:{" "}
            <span style={{ color: reqCurrentStatus && reqCurrentStatus.color }}>
              {reqCurrentStatus && reqCurrentStatus.title}
            </span>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Row className='mt-3'>
            <Col xs='auto' className='my-1'>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Field
                  type='select'
                  component={FieldInput}
                  name='status'
                  options={STATUSES}
                  defaultValue={status}
                />
              </Form.Group>
            </Col>
            <Col xs='auto' className='my-1'>
              <Button type='submit'>
                {updatingStatus ? "Йде зміна статусу..." : "Змінити статус"}
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <Row>
          <Col>
            <Row>
              <Col>
                <h4>Дані користувача:</h4>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <span className='bold'>Ім'я:</span> {name}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Email:</span> {email}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Телефон:</span> {phone}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Вік:</span> {age ? age : "N/A"}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Стать:</span> {sex ? sex : "N/A"}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Пріорітетний канал зв'язку:</span>{" "}
                {connectType}
              </Col>
            </Row>
            {
              connectType === 'telegram' && (
                    <Row>
                      <Col>
                        <span className='bold'>Telegram username:</span>{" "}
                        {telegramName}
                      </Col>
                    </Row>
                )
            }
          </Col>
          <Col>
            <Row>
              <Col>
                <h4>Дані психолога:</h4>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <span className='bold'>Ім'я:</span> {expertName}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Прізвище:</span> {expertLastName}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Email:</span> {expertEmail}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Телефон:</span> {expertPhone}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='bold'>Соц мережі:</span>
                <ul>
                  {expertSocialLinks &&
                    expertSocialLinks.map((socLink: string) => (
                      <li>
                        <a
                          href={socLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-break'
                        >
                          {socLink}
                        </a>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Коментар до запиту:</span> {comment || "N/A"}
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <h4>Результати тестів:</h4>
          </Col>
        </Row>
        {userRequest && (
          <>
            <Row className='mt-3'>
              <Col>
                <span className='bold'>Тип консультації:</span>{" "}
                {userRequest && sessionTypeTransform(userRequest.type)}
              </Col>
            </Row>
            {userRequest.theme && (
              <>
                <Row className='mt-2'>
                  <Col>
                    <span className='bold'>Результати автотесту:</span>
                  </Col>
                </Row>
                {renderAutotestResults(userRequest)}
              </>
            )}
            {userRequest.manual.request && (
              <>
                <Row className='mt-2'>
                  <Col>
                    <span className='bold'>Результати ручного запиту: </span>
                  </Col>
                </Row>
                <Row>
                  <Col>{renderManualResults(userRequest)}</Col>
                </Row>
              </>
            )}
          </>
        )}
        <Row>
          <Col>Досвід та освіта
            <Button
              className='mt-3'
              variant='info'
              onClick={() =>
                copyToClipboard({
                  name,
                  phone,
                  email,
                  age,
                  sex,
                  connectType,
                  telegramName,
                  userRequest,
                  comment
                })
              }
            >
              Копіювати дані користувача
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CurrentContactExpert;
