import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IStatState {
  loading: boolean;
  error: object | null;
  stat: {
    allUsersCount: number,
    autotestUsersCount: number,
    manualRequestsCount: number,
    contactExpertCount: number,
    expertsCount: number,
  };
};

export default combineReducers<IStatState>({
  loading(state: boolean = false, action) {
    switch (action.type) {
      case actions.GET_STAT_REQUEST:
        return true;
      case actions.GET_STAT_SUCCESS:
      case actions.GET_STAT_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.GET_STAT_FAILURE:
        return action.error || null;
      default:
        return state;
    }
  },
  stat(state: object = {}, action) {
    switch (action.type) {
      case actions.GET_STAT_SUCCESS:
        return action.data;
      case actions.GET_STAT_FAILURE:
        return state;
      default:
        return state;
    }
  },
});