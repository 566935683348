import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

export interface IProps {
  loading: boolean;
  isAuthenticated: boolean;
  component: any;
}

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  loading,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <div>lodaing</div>
            // <Spin
            //   size="large"
            //   style={{
            //     position: 'absolute',
            //     top: '50%',
            //   }}
            // />
          );
        }
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: ROUTES.LOGIN,
                state: { from: props.location },
              }}
            />
          );
      }}
    />
  );
};

export default PrivateRoute;
