import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getList } from '../../redux/manualRequests/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, manualRequests } from '../../redux/manualRequests/selectors';
import ManualRequests from './ManualRequests';

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  manualRequests,
});

const actions = {
  getList
};

export { ManualRequests };

export default connect(selector, actions)(ManualRequests);