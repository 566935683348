const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  EXPERTS: '/experts',
  USER_REQUESTS: '/user-requests',
  MANUAL_REQUESTS: '/manual-requests',
  PASSWORD_RECOVERY: '/password-recovery'
} as const;

export { ROUTES };
