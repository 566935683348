import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { Field } from 'redux-form';
import FieldInput from '../Input';

export interface IProps {
  handleSubmit(): void;
  errorLogin: {
    response: {
      text: string;
    };
  };
}

const LoginForm: React.FC<IProps> = (props) => {

  const { handleSubmit } = props;

  return (
    <Container className='h-100 d-flex flex-column align-items-center justify-content-center'>
      <Row className='w-100 d-flex justify-content-center m-4'>
        <h2>Login to dashboard</h2>
      </Row>
      <Row className='w-100 d-flex justify-content-center'>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>Email</Form.Label>
              <Field
                type='email'
                placeholder='Enter email'
                component={FieldInput}
                name='email'
              />
            </Form.Group>

            <Form.Group controlId='formBasicPassword'>
              <Form.Label>Password</Form.Label>
              <Field
                type='password'
                placeholder='Password'
                component={FieldInput}
                name='password'
              />
            </Form.Group>
            <Button variant='primary' type='submit' className='d-flex align-items-center'>
              Login
            </Button>
          </Form>
      </Row>
    </Container>
  );
};

export default LoginForm;
