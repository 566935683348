import { History } from 'history';
import { Action } from 'redux';
import { takeEvery } from 'redux-saga/effects';
import { IResponse } from '../reducer';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  PROFILE_SUCCESS,
  LOGIN_FAILURE,
} from './actions';

import { IApi } from '../../api';
import { ROUTES } from '../../constants/routes';

export function* loginSuccess(history: History, api: IApi) {
  yield takeEvery(LOGIN_SUCCESS, function*(action: IResponse & Action) {
    yield Cookies.set('user', action.data.user, {expires: 30});
    yield Cookies.set('token', action.data.token, {expires: 30});

    yield history.push(ROUTES.USER_REQUESTS);
  });
}

export function* loginFailure(history: History, api: IApi) {
  yield takeEvery(LOGIN_FAILURE, function*(action: IResponse & Action) {
    const errorMessage = action.error.response.text;
    yield toast.error(errorMessage);
  });
}

export function* signupSuccess() {
  yield takeEvery(SIGNUP_SUCCESS, function*() {
    yield null;
  });
}

export function* logoutSuccess(history: History, api: IApi) {
  yield takeEvery(LOGOUT_SUCCESS, () => {
    api.client.removeToken();
    history.push(ROUTES.LOGIN);
  });
}

export function* profileSuccess(history: History) {
  yield takeEvery(PROFILE_SUCCESS, function*() {});
}
