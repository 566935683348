import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { compose } from '../../utils';

import { getExpert, createExpert, editExpert } from '../../redux/expert/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, expert } from '../../redux/expert/selectors';
import CurrentExpert from './CurrentExpert';
import { INewExpertModel } from "../../models/Expert.model";

const validate = (values: any) => {
    const errors: { therapy: { price: { online: string | undefined, offline: string | undefined } } } = {
        therapy: {price: {online: undefined, offline: undefined}}
    }

    if (!values.therapy?.price?.online && !values.therapy?.price?.offline
    ) {
        errors.therapy.price.online = 'You must input at least one of price'
        errors.therapy.price.offline = 'You must input at least one of price'
    }

    return errors;
}


const formConnect = reduxForm({
    form: 'expert',
    enableReinitialize: true,
    /* @ts-ignore */
    validate
});

const selector = createStructuredSelector<IRootState, {}>({
    loading,
    error,
    initialValues: expert,
});

interface INewExpertModelExtended extends INewExpertModel {
    _id?: string
    __v?: number
}


const actions = {
    getExpert,
    onSubmit: (data: INewExpertModelExtended) => {
        const {_id = '', ...rest} = data;

        if (_id) {
            return editExpert(_id, rest)
        }

        return createExpert(rest)
    },
};

const reduxConnect = connect(selector, actions);

export { CurrentExpert };

export default compose(reduxConnect, formConnect)(withRouter(CurrentExpert));
