import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Experts from './experts';
import Stat from './stat';

const apiClient: ApiClient = new ApiClient(config.apiPrefix);

export interface IApi {
  auth: Auth;
  users: Users;
  client: ApiClient;
  experts: Experts;
  stat: Stat;
}

export default {
  auth: new Auth(apiClient),
  users: new Users(apiClient),
  client: apiClient,
  experts: new Experts(apiClient),
  stat: new Stat(apiClient),
};
