import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faCheck, faPlayCircle, faSpinner, faDatabase, faChalkboardTeacher, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

import api from './api';
import configureStore, { history } from './redux/store';

import Routes from './routes';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

const store = configureStore(api, history);
library.add(
  fab,
  faCheckCircle,
  faCheck,
  faPlayCircle,
  faSpinner,
  faDatabase,
  faChalkboardTeacher,
  faEdit,
);
history.listen((_) => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
});

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <Routes />
            <ToastContainer />
          </LastLocationProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}
