import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '../../redux/reducer';
import { getList, toggleHideExpert } from '../../redux/expert/actions';
import { loading, error, experts } from '../../redux/expert/selectors';

import Experts from './Experts';

const selector = createStructuredSelector<IRootState, {}>({
    loading,
    error,
    experts,
});

const actions = {
    getList,
    editExpert: toggleHideExpert,
};

export { Experts };

export default connect(selector, actions)(Experts);
