export const THEMES = [
  {
    id: 'A',
    title: 'Кар\'єра, робота (відносини з коллегами, етика відносин в колективі)',
    detailed: [
      {
        id: 'A',
        title: 'Невдоволення роботою',
      },
      {
        id: 'B',
        title: 'Хочу змінити вид діяльності',
      },
      {
        id: 'C',
        title: 'Не вдається заощадити кошти',
      },
      {
        id: 'D',
        title: 'Мало заробляю',
      },
      {
        id: 'E',
        title: 'Дозволяю іншим порушувати мої особисті рамки',
      },
      {
        id: 'F',
        title: 'Втрата роботи',
      },
      {
        id: 'G',
        title: 'Напружені стосунки з керівником',
      },
      {
        id: 'H',
        title: 'Турбують стосунки з підлеглими'
      },
    ],
  },
  {
    id: 'B',
    title: 'Пошук себе (екзистенційна криза)',
    detailed: [
      {
        id: 'A',
        title: 'Проблеми з самооцінкою',
      },
      {
        id: 'B',
        title: 'Невпевненість в собі',
      },
      {
        id: 'C',
        title: 'Не можу вирішити (вагаюсь), чим я хочу займатись',
      },
      {
        id: 'D',
        title: 'Відчуваю необхідність сформувати більш чіткі або нові цілі ',
      },
      {
        id: 'E',
        title: 'Складність у прийнятті рішень',
      },
      {
        id: 'F',
        title: 'Труднощі гендерної самоідентифікації та сексуальності',
      },
      {
        id: 'H',
        title: 'Не можу знайти сенс життя',
      },
    ],
  },
  {
    id: 'C',
    title: 'Необхідне перезавантаження (втома, професійне вигорання)',
    detailed: [
      {
        id: 'A',
        title: 'Відсутність зацікавленності до звичних справ',
      },
      {
        id: 'B',
        title: 'Виснаження без вагомої причини',
      },
      {
        id: 'C',
        title: 'Відсутність бажання що-небудь робити',
      },
      {
        id: 'D',
        title: 'Часті зміни настрою',
      },
      {
        id: 'E',
        title: 'Емоційна нестабільність',
      },
      {
        id: 'F',
        title: 'Світ здається у сірих кольорах'
      },
    ],
  },
  {
    id: 'D',
    title: 'Тривожність, депресія, панічні атаки',
    detailed: [
      {
        id: 'A',
        title: 'Емоційна нестабільність',
      },
      {
        id: 'B',
        title: 'Відсутність бажання що-небудь робити',
      },
      {
        id: 'C',
        title: 'Складнощі із засинанням, поверхневий чи переривчастий сон',
      },
      {
        id: 'D',
        title: 'Частий поганий настрій',
      },
      {
        id: 'E',
        title: 'Поганої думки про себе, вважаю себе невдахою',
      },
      {
        id: 'F',
        title: 'Страх, що відбудеться щось погане',
      },
      {
        id: 'G',
        title: 'Часті відчуття прискореного серцебиття',
      },
      {
        id: 'H',
        title: 'Відчуття нестачі повітря',
      },
      {
        id: 'I',
        title: 'Запаморочення',
      },
      {
        id: 'J',
        title: 'Надмірна пітливість (не пов\'язане із заняттями спортом чи спекотною погодою)',
      },
      {
        id: 'K',
        title: 'Втрата роботи',
      },
      {
        id: 'L',
        title: 'Тяжкі захворювання',
      },
      {
        id: 'M',
        title: 'Втрата близької людини',
      },
    ],
  },
  {
    id: 'E',
    title: 'Керування стресом, агресією',
    detailed: [
      {
        id: 'A',
        title: 'Часті прояви агресії та дратівливості',
      },
      {
        id: 'B',
        title: 'Пригнічений стан',
      },
      {
        id: 'C',
        title: 'Часті зміни настрою',
      },
      {
        id: 'D',
        title: 'Емоційна нестабільність',
      },
      {
        id: 'E',
        title: 'Складнощі із засинанням, поверхневий чи переривчастий сон',
      },
      {
        id: 'F',
        title: 'Зміни апетиту (поганий апетит чи переїдання)',
      },
      {
        id: 'G',
        title: 'Важко зосередитись',
      },
    ],
  },
  {
    id: 'F',
    title: 'Пошук другої половинки ("мені ніхто не підходить")',
    detailed: [
      {
        id: 'A',
        title: 'Складнощі при знайомстві',
      },
      {
        id: 'B',
        title: 'Складно побудувати довготривалі відносини',
      },
      {
        id: 'C',
        title: 'Страх "впустити" кого-небудь у своє життя',
      },
      {
        id: 'D',
        title: 'Негативний досвід минулих стосунків',
      },
      {
        id: 'E',
        title: 'Невпевненість у своїй привабливості',
      },
      {
        id: 'F',
        title: 'Страх самотності',
      },
    ],
  },
  {
    id: 'G',
    title: 'Взаємовідносини з батьками',
    detailed: [
      {
        id: 'A',
        title: 'Батьківська "диктатура", нав\'язування батьками своєї думки',
      },
      {
        id: 'B',
        title: 'Мирне співіснування (прихована конфліктна ситуація)',
      },
      {
        id: 'C',
        title: 'Конфлікт опіки (надмірна опіка батьків)',
      },
      {
        id: 'D',
        title: 'Адаптація в умовах спільного помешкання',
      },
      {
        id: 'E',
        title: 'Відчуття провини перед батьками',
      },
      {
        id: 'F',
        title: 'Батьки постійно звинувачують',
      },
      {
        id: 'G',
        title: 'Завищені очікування',
      },
    ],
  },
  {
    id: 'H',
    title: 'Стосунки з дітьми (дитячий садок, школа)',
    detailed: [
      {
        id: 'A',
        title: 'Порушення поведінки дитини',
      },
      {
        id: 'B',
        title: 'Тестування психологічної готовності дитини до школи',
      },
      {
        id: 'C',
        title: 'Адаптація до дитячого садка, школи',
      },
      {
        id: 'D',
        title: 'Проблеми з навчанням',
      },
      {
        id: 'E',
        title: 'Дитина в "кризовому" періоді',
      },
      {
        id: 'F',
        title: 'Сімейні складнощі (переїзд, розлучення, смерть)',
      },
      {
        id: 'G',
        title: 'Фізіологічні розлади',
      },
      {
        id: 'H',
        title: 'Залежності',
      },
    ],
  },
  {
    id: 'I',
    title: 'Стосунки з другою половинкою',
    detailed: [
      {
        id: 'A',
        title: 'Часті конфлікти',
      },
      {
        id: 'B',
        title: 'Невпевненість у відносинах',
      },
      {
        id: 'C',
        title: 'Проблеми в інтимному житті',
      },
      {
        id: 'D',
        title: 'Зрада',
      },
      {
        id: 'E',
        title: 'Стосунки на межі розлучення',
      },
      {
        id: 'F',
        title: 'Процес розлучення',
      },
      {
        id: 'G',
        title: 'Складний період у партнера',
      },
      {
        id: 'H',
        title: 'Насилля у сім\'ї',
      }
    ],
  },
  {
    id: 'J',
    title: 'Планування вагітності, вагітність та післяпологовий період',
    detailed: [
      {
        id: 'A',
        title: 'Планування вагітності',
      },
      {
        id: 'B',
        title: 'Довготривале ненастання вагітності',
      },
      {
        id: 'C',
        title: 'Невиношування вагітності',
      },
      {
        id: 'D',
        title: 'Питання під час вагітності',
      },
      {
        id: 'E',
        title: 'Психологічна підготовка до пологів',
      },
      {
        id: 'F',
        title: 'Післяпологовий період',
      },
      {
        id: 'G',
        title: 'Депресивні настрої після пологів',
      },
    ],
  },
] as const;