import { IApi } from '../../api';

export const prefix = 'contactExperts';

export const GET_ALL_CONTACT_EXPERTS_REQUEST = `${prefix}/GET_ALL_CONTACT_EXPERTS_REQUEST`;
export const GET_ALL_CONTACT_EXPERTS_SUCCESS = `${prefix}/GET_ALL_CONTACT_EXPERTS_SUCCESS`;
export const GET_ALL_CONTACT_EXPERTS_FAILURE = `${prefix}/GET_ALL_CONTACT_EXPERTS_FAILURE`;

export const getList = (limit?: number) => {
  return {
    type: [
      GET_ALL_CONTACT_EXPERTS_REQUEST,
      GET_ALL_CONTACT_EXPERTS_SUCCESS,
      GET_ALL_CONTACT_EXPERTS_FAILURE,
    ],
    promise: (api: IApi) => api.experts.getAllContactExperts({ limit }),
  };
};

export const GET_CONTACT_EXPERT_REQUEST = `${prefix}/GET_CONTACT_EXPERT_REQUEST`;
export const GET_CONTACT_EXPERT_SUCCESS = `${prefix}/GET_CONTACT_EXPERT_SUCCESS`;
export const GET_CONTACT_EXPERT_FAILURE = `${prefix}/GET_CONTACT_EXPERT_FAILURE`;

export const getContactExpert = (contactId: string) => {
  return {
    type: [GET_CONTACT_EXPERT_REQUEST, GET_CONTACT_EXPERT_SUCCESS, GET_CONTACT_EXPERT_FAILURE],
    promise: (api: IApi) => api.experts.getContactExpert(contactId),
  };
};

export const CHANGE_CONTACT_EXPERTS_STATUS_REQUEST = `${prefix}/CHANGE_CONTACT_EXPERTS_STATUS_REQUEST`;
export const CHANGE_CONTACT_EXPERTS_STATUS_SUCCESS = `${prefix}/CHANGE_CONTACT_EXPERTS_STATUS_SUCCESS`;
export const CHANGE_CONTACT_EXPERTS_STATUS_FAILURE = `${prefix}/CHANGE_CONTACT_EXPERTS_STATUS_FAILURE`;

export const changeStatus = (
  data: {
    _id: string,
    status: string,
}[]) => {
  return {
    type: [
      CHANGE_CONTACT_EXPERTS_STATUS_REQUEST,
      CHANGE_CONTACT_EXPERTS_STATUS_SUCCESS,
      CHANGE_CONTACT_EXPERTS_STATUS_FAILURE,
    ],
    promise: (api: IApi) => api.experts.changeStatus(data),
  };
};
