import React, { useEffect, useCallback, Component } from 'react';
import { Row, Col, Container, Button, Form, Figure } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import { Link } from 'react-router-dom';
import FieldInput from '../Input';

import SelectMapper from "./SelectMapper";

import { TAB_LIST } from "../../constants/tabsList";

import { IExpertModel } from "../../models/Expert.model";
import config from '../../config';

interface IProps {
    getExpert?(): void;

    initialValues: any

    expert?: IExpertModel;

    handleSubmit(): void;

    onSubmit(): void;
}

const required = (value: string | number | Array<string | number>) => {
    if (Array.isArray(value)) {
        return (value.length && value.every(fieldValue => fieldValue !== undefined)) ? undefined : 'Required'
    }
    return value ? undefined : 'Required'
};


const renderField = ({label, type, input}: { label: string, type: string, input: any }) => (
    <div>
        <label>{label}</label>
        <div>
            <input type={type} placeholder={label}/>
        </div>
    </div>
);
const renderSocialLinks = ({fields}: { fields: any }) => (
    <>
        <Button type="button" onClick={() => fields.push()}>
            Додати соціальну мережу
        </Button>
        <ul className='p-xl-0'>
            {fields.map((socialLink: string, index: number) => (
                <li key={index} style={{display: 'flex', alignItems: 'flex-end', margin: "10px 0"}}>
                    <Button className='mb-3' type="button" onClick={() => fields.remove(index)}
                            style={{marginRight: "10px"}}>
                        Видалити
                    </Button>
                    <Form.Group controlId={`form${socialLink}`}>
                        <Form.Label>{`Посилання соц. мережі #${index + 1}`}</Form.Label>
                        <Field
                            name={`${socialLink}`}
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </li>
            ))}
        </ul>
    </>
);

const renderBadges = ({fields, meta: {submitFailed, error}}: { fields: any, meta: any }) => (
    <>
        <Button type="button" onClick={() => fields.push()}>
            Додати тег напрямку терапії
        </Button>

        {submitFailed && (error && <span style={{color: "red"}}>{error}</span>)}

        <ul className='p-xl-0'>
            {fields.map((badge: string, index: number) => (
                <li key={index} style={{display: 'flex', alignItems: 'flex-end', margin: "10px 0"}}>
                    <Button className='mb-3' type="button" onClick={() => fields.remove(index)}
                            style={{marginRight: "10px"}}>
                        Видалити
                    </Button>
                    <Form.Group controlId={`form${badge}`}>
                        <Form.Label>{`Тег #${index + 1}`}</Form.Label>
                        <Field
                            name={`${badge}`}
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </li>
            ))}
        </ul>
    </>
);

const renderTheme = ({fields, meta: {submitFailed, error}}: { fields: any, meta: any }) => {

    const selectOptions = TAB_LIST.map(({id, text}) => ({value: id, title: text}))

    const onSelect = (value: string) => fields.push(value)
    const onDelete = (index: number) => fields.remove(index)


    return (
        <>
            <SelectMapper onSelect={onSelect}
                          onDelete={onDelete}
                          title='Оберіть тематику'
                          items={fields.getAll()}
                          options={selectOptions}/>
            {submitFailed && (error && <span style={{color: "red"}}>{error}</span>)}
        </>
    )
}

class FieldFileInput extends Component<any, any> {
    // TODO: Save photo to s3 and return link only
    // @ts-ignore


    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.state = {
            src: ''
        };
    }

    async onChange(e: { target: { files: any[]; }; }) {
        // @ts-ignore
        const {input: {onChange}} = this.props
        const file = e.target.files[0];
        onChange(file)

        const url = await this.readURL(file);
        this.setState({src: url})
    }

    makeSrc(name: string) {
        if (!this.props.expertId || this.state.src) return this.state.src
        return `${config.s3_bucket}/${this.props.expertId}/${name}`
    }


    readURL(file: File) {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = e => res(e.target?.result);
            reader.onerror = e => rej(e);
            reader.readAsDataURL(file);
        });
    }

    render() {
        const {
            input: {value},
            label,
            accept,
            meta: {touched, error, warning}
        } = this.props as { label: string, accept?: string, meta?: any, input: any };

        return (
            <>
                <Form.Control
                    id={label}
                    type='file'
                    accept={accept}
                    onChange={this.onChange as any}
                />

                {touched && ((error && <span style={{color: "red"}}>{error}</span>) || (warning &&
                    <span style={{color: "red"}}>{warning}</span>))}

                {!this.props.isVideo
                    ?
                    (<Figure>
                        <Figure.Image
                            width={380}
                            alt={value}
                            src={this.makeSrc(value)}
                        />
                    </Figure>)
                    :
                    (<div>
                        <video width={380} src={this.makeSrc(value)} controls></video>
                    </div>)
                }

            </>

        )
    }
}

const renderLanguages = ({fields, meta: {submitFailed, error}}: { fields: any, meta: any }) => (
    <>
        <Button type="button" onClick={() => fields.push()}>
            Додати мову
        </Button>

        {submitFailed && (error && <span style={{color: "red"}}>{error}</span>)}

        <ul className='p-xl-0'>
            {fields.map((language: string, index: number) => (
                <li key={index} style={{display: 'flex', alignItems: 'flex-end', margin: "10px 0"}}>
                    <Button className='mb-3' type="button" onClick={() => fields.remove(index)}
                            style={{marginRight: "10px"}}>
                        Видалити
                    </Button>

                    <Form.Group controlId={`form${language}`}>
                        <Form.Label>{`Мова #${index + 1}`}</Form.Label>
                        <Field
                            name={`${language}`}
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </li>
            ))}
        </ul>
    </>
);

const renderDiplomas = ({
                            fields,
                            expertId,
                            meta: {submitFailed, error}
                        }: { expertId: any, fields: any, meta?: any }) => (
    <>
        <Button type="button" onClick={() => fields.push()}>
            Додати диплом
        </Button>

        {submitFailed && (error && <span style={{color: "red"}}>{error}</span>)}

        <ul className='p-xl-0'>
            {fields.map((diploma: string, index: number) => (
                <li key={diploma} style={{display: 'flex', alignItems: 'flex-start', margin: "10px 0"}}>
                    <Button className='mb-3' type="button" onClick={() => fields.remove(index)}
                            style={{marginRight: "10px"}}>
                        Видалити
                    </Button>
                    <Form.Group controlId={`form${diploma}`}>
                        <Form.Label>{`Диплом #${index + 1}`}</Form.Label>
                        <Field
                            expertId={expertId + `/diplomas`}
                            name={`${diploma}`}
                            type="text"
                            accept='.jpg, .png, .jpeg'
                            component={FieldFileInput}
                        />
                    </Form.Group>
                </li>
            ))}
        </ul>
    </>
);


const CurrentExpert: React.FC<IProps & any> = (props): JSX.Element => {
    const {handleSubmit, getExpert, formState, match} = props;
    const expertId = match.params?.id;
    const backToTable = match.path.replace("/:id", "");



    const handleGetExpert = useCallback(async () => {
        await getExpert(expertId);
    }, [expertId])

    useEffect(() => {
        if (expertId) {
            handleGetExpert()
                .catch((e) => alert(e.message));
        }
    }, [])

    return (
        <Container fluid="sm">
            <Row className='mt-4 mb-4'>
                <Col className='d-sm-flex justify-content-center'>
                    <Link to={backToTable ? backToTable : "/"}>
                        <Button>Назад до таблиці</Button>
                    </Link>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formFirstName' className='mr-4'>
                        <Form.Label className='required'>Індефікатор експерта</Form.Label>
                        <Field
                            validate={[required]}
                            placeholder='Наприклад: 13-dmytro'
                            name="expertId"
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formFirstName' className='mr-4'>
                        <Form.Label>Ім'я</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.firstName"
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formLastName'>
                        <Form.Label>Фамілія</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.lastName"
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formEmail' className='mr-4'>
                        <Form.Label>Email</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.email"
                            type="email"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formPhone'>
                        <Form.Label>Номер телефону</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.phone"
                            type="phone"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">

                    <Form.Group controlId='formAge' className='mr-4'>
                        <Form.Label>Вік</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.age"
                            type="number"
                            component={FieldInput}
                        />
                    </Form.Group>


                    <Form.Group controlId='formSocialLinks' className='d-xl-flex flex-column'>
                        <Form.Label>Соціальні мережі</Form.Label>
                        <FieldArray name="profile.socialLinks" component={renderSocialLinks}/>
                    </Form.Group>

                </Row>

                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formPhoto' className='mr-4'>
                        <Form.Label>Фото</Form.Label>
                        <Field
                            validate={[required]}
                            name="profile.photoUrl"
                            expertId={expertId}
                            component={FieldFileInput}
                            accept='.jpg, .png, .jpeg'
                        />
                    </Form.Group>
                    <Form.Group controlId='formVideo'>
                        <Form.Label>Відео</Form.Label>
                        <Field
                            isVideo
                            expertId={expertId}
                            name="profile.videoUrl"
                            component={FieldFileInput}
                            accept='.mp4, .mov, .webm'
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formAbout' className='mr-4'>
                        <Form.Label>Про себе</Form.Label>
                        <Field
                            as='textarea'
                            type='textarea'
                            name="profile.about"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formShortDescription'>
                        <Form.Label>Короткий опис</Form.Label>
                        <Field
                            as='textarea'
                            type='textarea'
                            name="profile.shortDescription"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formPaymentCard' className='mr-4'>
                        <Form.Label>Розрахункова карта</Form.Label>
                        <Field
                            type='text'
                            name="paymentCard"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formDiplomas' className='d-xl-flex flex-column'>
                        <Form.Label>Дипломи</Form.Label>
                        <FieldArray props={{expertId}} validate={[required]} name="experience.diplomas"
                                    component={renderDiplomas}/>
                    </Form.Group>
                </Row>

                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formEducationBasic' className='mr-4'>
                        <Form.Label>Базова освіта</Form.Label>
                        <Field
                            validate={[required]}
                            name="education.basic"
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formEducationExtra'>
                        <Form.Label>Додаткова освіта</Form.Label>
                        <Field
                            name="education.extra"
                            type="text"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formExperienceYears' className='mr-4'>
                        <Form.Label>Років досвіду</Form.Label>
                        <Field
                            name="experience.years"
                            type="number"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formExperienceInfoText'>
                        <Form.Label>Описання досвіду</Form.Label>
                        <Field
                            as='textarea'
                            name="experience.infoText"
                            type="textarea"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formExperiencePersonalPractice' className='mr-4'>
                        <Form.Label>Досвід перосанальної практики</Form.Label>
                        <Field
                            validate={[required]}
                            as='textarea'
                            type="textarea"
                            name="experience.personalPractice"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formExperienceSupervision'>
                        <Form.Label>Досвід cупервізій</Form.Label>
                        <Field
                            as='textarea'
                            name="experience.supervision"
                            type="textarea"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formTherapyThemeBadges' className='d-xl-flex flex-column mr-4'>
                        <Form.Label>Напрямки терапії</Form.Label>
                        <FieldArray validate={[required]} name="therapy.themeBadges" component={renderBadges}/>
                    </Form.Group>
                    <Form.Group controlId='formTherapyThemeBadges'>
                        <Form.Label>Ви допомагаєте вирішити такі питання</Form.Label>
                        <FieldArray validate={[required]} name="therapy.theme"
                                    component={renderTheme}/>
                    </Form.Group>
                </Row>

                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formTherapyPriceOnline' className='mr-4'>
                        <Form.Label>Ціна online</Form.Label>
                        <Field
                            type="number"
                            name="therapy.price.online"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formTherapyPriceOffline'>
                        <Form.Label>Ціна offline</Form.Label>
                        <Field
                            name="therapy.price.offline"
                            type="number"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>

                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formTherapyAddress' className='mr-4'>
                        <Form.Label>Адреса</Form.Label>
                        <Field
                            type="text"
                            name="therapy.address"
                            component={FieldInput}
                        />
                    </Form.Group>
                    <Form.Group controlId='formTherapyComments'>
                        <Form.Label>Коментар</Form.Label>
                        <Field
                            as='textarea'
                            name="therapy.comments"
                            type="textarea"
                            component={FieldInput}
                        />
                    </Form.Group>
                </Row>
                <Row lg={3} className="justify-content-md-center">
                    <Form.Group controlId='formTherapyContacts' className='mr-4'>
                        <Form.Label>Контакти</Form.Label>
                        <Form.Group controlId='formTherapyContactsSkype'>
                            <Form.Label>Skype</Form.Label>
                            <Field
                                type="text"
                                name="therapy.contacts.skype"
                                component={FieldInput}
                            />
                        </Form.Group>
                        <Form.Group controlId='formTherapyContactsZoom'>
                            <Form.Label>Zoom</Form.Label>
                            <Field
                                name="therapy.contacts.zoom"
                                type="text"
                                component={FieldInput}
                            />
                        </Form.Group>
                        <Form.Group controlId='formTherapyContactsGoogleHangouts'>
                            <Form.Label>Google Hangouts</Form.Label>
                            <Field
                                name="therapy.contacts.googleHangouts"
                                type="text"
                                component={FieldInput}
                            />
                        </Form.Group>
                    </Form.Group>

                    <Form.Group controlId='formTherapyLanguages' className='d-xl-flex flex-column'>
                        <Form.Label>Мови</Form.Label>
                        <FieldArray validate={[required]} name="therapy.languages" component={renderLanguages}/>
                    </Form.Group>
                </Row>

                <Row className='d-sm-flex justify-content-center'>
                    <Button type="submit" style={{marginTop: "20px"}}>
                        Зберегти психолога
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default CurrentExpert;
