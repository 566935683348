import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// @ts-ignore
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faChalkboardTeacher, faUserMd } from '@fortawesome/free-solid-svg-icons'
import PrivateRoute from '../PrivateRoute';
import UserRequests from '../UserRequests/index';
import Experts from '../Experts/index';
import CurrentExpert from '../CurrentExpert/index';
import { ROUTES } from '../../constants/routes';
import colors from '../../assets/styles/colorsToJs.module.scss';
import ManualRequests from '../ManualRequests';
import CurrentManualRequest from '../CurrentManualRequest';
import CurrentContactExpert from '../CurrentContactExpert';
import Stat from '../Stat';


const NavBar: React.FC = (props: any) => {
  const NavStyle = {
    background: colors.orange,
    position: 'fixed'
  };

  return (
    <Route
      render={({ location, history }) => (
        <React.Fragment>
          <SideNav
            style={NavStyle}
            onSelect={(selected: any) => {
              const to = "/" + selected;
              if (location.pathname !== to) {
                history.push(to);
              }
            }}
            onToggle={(expanded: boolean) => {
              // this.setState({ expanded });
            }}
          >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected={location.pathname.replace('/', '')}>
              <NavItem eventKey='user-requests'>
                <NavIcon>
                  <FontAwesomeIcon icon={faDatabase} />
                </NavIcon>
                <NavText>Запити користувачів</NavText>
              </NavItem>
              <NavItem eventKey='manual-requests'>
                <NavIcon>
                  <FontAwesomeIcon icon={faChalkboardTeacher} />
                </NavIcon>
                <NavText>Ручний підбір псхолога</NavText>
              </NavItem>
                <NavItem eventKey='experts'>
                    <NavIcon>
                        <FontAwesomeIcon icon={faUserMd} />
                    </NavIcon>
                    <NavText>Психологи</NavText>
                </NavItem>
            </SideNav.Nav>
          </SideNav>
          <main>
              <Stat />

              <PrivateRoute path={ROUTES.EXPERTS} exact component={() => <Experts/>}/>
              <PrivateRoute path={`${ROUTES.EXPERTS}/:id`} component={() => <CurrentExpert/>}/>

              <PrivateRoute path={ROUTES.USER_REQUESTS} exact component={() => <UserRequests/>}/>
              <PrivateRoute path={`${ROUTES.USER_REQUESTS}/:id`} component={() => <CurrentContactExpert/>}/>

              <PrivateRoute path={ROUTES.MANUAL_REQUESTS} exact component={() => <ManualRequests/>}/>
              <PrivateRoute path={`${ROUTES.MANUAL_REQUESTS}/:id`} component={() => <CurrentManualRequest />} />
          </main>
        </React.Fragment>
      )}
    />
  );
};

export default NavBar;