import superagent, {Request} from 'superagent';
import Cookies from 'js-cookie';
import config from '../config';

export default class ApiClient {
    prefix: string;

    constructor(prefix: string) {
        if (!prefix) {
            throw new Error('[apiPrefix] required');
        }
        this.prefix = prefix;
    }

    removeToken() {
        Cookies.remove('token');
        Cookies.remove('user')
    }

    setToken(req: Request) {
        const token = Cookies.get('token');

        if (token) {
            req.set('Authorization', `Bearer ${token}`);
        }
    }

    get(url: string, params: object = {}) {
        return this._request({url, method: 'get', params});
    }

    post(url: string, body: object = {}) {
        return this._request({url, method: 'post', body});
    }

    patch(url: string, body: object = {}) {
        return this._request({url, method: 'patch', body});
    }

    put(url: string, body: object = {}) {
        return this._request({url, method: 'put', body});
    }

    delete(url: string, body: object = {}) {
        return this._request({url, method: 'delete', body});
    }

    _request({
                 url,
                 method,
                 params,
                 body,
             }: {
        url: string;
        method: string;
        params?: object;
        body?: object;
    }) {
        const {api_host, api_port} = config;

        // @ts-ignore
        const req = superagent[method](`${api_host}${api_port && `:${api_port}`}${this.prefix}${url}`);
        // req.withCredentials();

        if (params) {
            req.query(params);
        }
        if (body) {
            req.send(body);
        }

        this.setToken(req);

        return req.then((res: any) => {
            if (res.status !== 200) {
                throw res.body.error;
            }
            return res.body.data;
        });
    }

    _customRequest({url, method}: { url: string; method: string; }) {
        const {api_host, api_port} = config;
        // @ts-ignore
        const req = superagent[method](`${api_host}${api_port && `:${api_port}`}${this.prefix}${url}`);

        this.setToken(req);

        return req
    }

}
