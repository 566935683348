import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IManualReqState {
  loading: boolean;
  error: object | null;
  manualRequests: object[];
  updatingStatus: boolean;
  currentManualRequest: object;
};

export default combineReducers<IManualReqState>({
  loading(state: boolean = false, action) {
    switch (action.type) {
      case actions.GET_ALL_USER_MANUAL_REQ_REQUEST:
      case actions.GET_MANUAL_REQ_REQUEST:
        return true;
      case actions.GET_ALL_USER_MANUAL_REQ_SUCCESS:
      case actions.GET_ALL_USER_MANUAL_REQ_FAILURE:
      case actions.GET_MANUAL_REQ_SUCCESS:
      case actions.GET_MANUAL_REQ_FAILURE:
        return false;
      default:
        return state;
    }
  },
  updatingStatus(state: boolean = false, action) {
    switch (action.type) {
      case actions.CHANGE_USER_MANUAL_REQ_STATUS_REQUEST:
        return true;
      case actions.CHANGE_USER_MANUAL_REQ_STATUS_SUCCESS:
      case actions.CHANGE_USER_MANUAL_REQ_STATUS_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.GET_ALL_USER_MANUAL_REQ_FAILURE:
        return action.error || null;
      default:
        return state;
    }
  },
  manualRequests(state: object[] = [], action) {
    switch (action.type) {
      case actions.GET_ALL_USER_MANUAL_REQ_SUCCESS:
        return action.data;
      case actions.GET_ALL_USER_MANUAL_REQ_FAILURE:
        return state;
      default:
        return state;
    }
  },
  currentManualRequest(state: object = {}, action) {
    switch (action.type) {
      case actions.GET_MANUAL_REQ_SUCCESS:
        return action.data;
      case actions.CHANGE_USER_MANUAL_REQ_STATUS_SUCCESS:
        const updStatuses = action.data;
        const newState = { ...state, status: updStatuses.status, updatedAt: updStatuses.updatedAt }
        return newState;
      case actions.GET_MANUAL_REQ_FAILURE:
        return {};
      default:
        return state;
    }
  },
});
