import { prefix } from './actions';
import { createSelector } from 'reselect';
import Cookies from 'js-cookie';
import { IRootState } from '../reducer';

import { USER_ROLES } from '../../constants/roles';

export const loading = (state: IRootState) => state[prefix].loading;
export const user = (state: IRootState) => state[prefix].user;
export const role = (state: IRootState) => state[prefix].user && (state[prefix].user as any).role;
export const isAdmin = (state: IRootState) =>
  state[prefix].user && (state[prefix].user as any).role === USER_ROLES.ADMIN;
export const error = (state: IRootState) => state[prefix].error;
export const isConfirmed = (state: IRootState) => state[prefix].isConfirmed;
export const isChangedPwd = (state: IRootState) => state[prefix].isChangedPwd;
export const isRecoveryRequested = (state: IRootState) => state[prefix].isRecoveryRequested;
export const successfullyRegistered = (state: IRootState) =>
  state[prefix].successfullyRegistered;

export const isAuthenticated = createSelector(
  user,
  user => !!Cookies.get('user'),
);