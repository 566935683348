import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IContactState {
  loading: boolean;
  error: object | null;
  contactExperts: object[];
  updatingStatus: boolean;
  currentContactExpert: object;
};

export default combineReducers<IContactState>({
  loading(state: boolean = false, action) {
    switch (action.type) {
      case actions.GET_ALL_CONTACT_EXPERTS_REQUEST:
        return true;
      case actions.GET_ALL_CONTACT_EXPERTS_SUCCESS:
      case actions.GET_ALL_CONTACT_EXPERTS_FAILURE:
        return false;
      default:
        return state;
    }
  },
  updatingStatus(state: boolean = false, action) {
    switch (action.type) {
      case actions.CHANGE_CONTACT_EXPERTS_STATUS_REQUEST:
        return true;
      case actions.CHANGE_CONTACT_EXPERTS_STATUS_SUCCESS:
      case actions.CHANGE_CONTACT_EXPERTS_STATUS_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.GET_ALL_CONTACT_EXPERTS_FAILURE:
        return action.error || null;
      default:
        return state;
    }
  },
  contactExperts(state: object[] = [], action) {
    switch (action.type) {
      case actions.GET_ALL_CONTACT_EXPERTS_SUCCESS:
        return action.data;
      case actions.GET_ALL_CONTACT_EXPERTS_FAILURE:
        return [];
      default:
        return state;
    }
  },
  currentContactExpert(state: object = {}, action) {
    switch (action.type) {
      case actions.GET_CONTACT_EXPERT_SUCCESS:
        return action.data;
      case actions.CHANGE_CONTACT_EXPERTS_STATUS_SUCCESS:
        const updStatuses = action.data;
        const newState = { ...state, status: updStatuses.status, updatedAt: updStatuses.updatedAt }
        return newState;
      case actions.GET_CONTACT_EXPERT_FAILURE:
        return {};
      default:
        return state;
    }
  },
});