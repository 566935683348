export const TAB_LIST = [
  {
    id: 'A',
    text: 'Кар\'єра, робота (відносини з коллегами, етика відносин в колективі)',
  },
  {
    id: 'B',
    text: 'Пошук себе (екзистенційна криза)',
  },
  {
    id: 'C',
    text: 'Необхідне перезавантаження (втома, професійне вигорання)',
  },
  {
    id: 'D',
    text: 'Тривожність, депресія, панічні атаки',
  },
  {
    id: 'E',
    text: 'Керування стресом, агресією',
  },
  {
    id: 'F',
    text: 'Пошук другої половинки ("мені ніхто не підходить")',
  },
  {
    id: 'G',
    text: 'Взаємовідносини з батьками',
  },
  {
    id: 'H',
    text: 'Стосунки з дітьми (дитячий садок, школа)',
  },
  {
    id: 'I',
    text: 'Стосунки з другою половинкою',
  },
  {
    id: 'J',
    text: 'Планування вагітності, вагітність та післяпологовий період',
  },
] as const;
