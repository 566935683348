import React, { useEffect } from 'react';
import { ROUTES } from '../../constants/routes';
import PaginationTable from '../PaginationTable';

interface IProps {
  getList?(): void;
  contactExperts?: {
    connectType: string,
    createdAt: string,
    updatedAt?: string,
    userRequest?: {
      theme?: string[],
      detailed?: object,
      type: string,
      manual?: {
        request: string,
        specialistRequest?: string,
      }
    },
    email: string,
    expertEmail: string,
    comment?: string,
    // expertId: string,
    expertLastName: string,
    expertName: string,
    expertPhone: string,
    expertSocialLinks?: string[],
    name: string,
    phone: string,
    age?: string,
    sex?: string,
    status: string,
    _id: string,
  }[];
}

const UserRequest: React.FC<IProps> = (props: any): JSX.Element => {
  const { getList, contactExperts } = props;

  useEffect(() => {
    getList && getList();
  }, []);

  return (
    <PaginationTable
      data={contactExperts}
      route={ROUTES.USER_REQUESTS}
      header='Запити на контакт з психологом'
    />
  );
};

export default UserRequest;