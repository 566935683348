import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getList } from '../../redux/contactExpert/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, contactExperts } from '../../redux/contactExpert/selectors';
import UserRequests from './UserRequests';

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  contactExperts,
});

const actions = {
  getList,
};

export { UserRequests };

export default connect(selector, actions)(UserRequests);