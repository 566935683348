import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

interface IProps {
  input: any,
  meta: any,
  type: string,
  placeholder?: string,
  min?: number,
  max?: number,
  options?: { value: string, title: string }[],
  defaultValue: string;
  as: any;
}

const FieldInput = ({ input, type, placeholder, min, max, options, defaultValue, as, meta: { touched, error, warning } }: IProps) => {
  if (type === 'select') return (
      <FormControl as='select' value={input.value || defaultValue} onChange={input.onChange}>
        {options && options.map((option, index) => (
          <option key={index} value={option.value}>{option.title}</option>
        ))}
      </FormControl>
    );

  return (
      <>
          <FormControl
              as={as}
              type={type}
              placeholder={placeholder}
              min={min}
              max={max}
              value={input.value}
              onChange={input.onChange}
          />
          {touched && ((error && <span style={{color: "red"}}>{error}</span>) || (warning && <span style={{color: "red"}}>{warning}</span>))}
      </>


  );
};

export default FieldInput;
