import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { compose } from '../../utils';

import { getContactExpert, changeStatus } from '../../redux/contactExpert/actions';
import { IRootState } from '../../redux/reducer';

import { loading, error, currentContactExpert, updatingStatus } from '../../redux/contactExpert/selectors';
import CurrentContactExpert from './CurrentContactExpert';

const formConect = reduxForm<{ status: string }, {}>({
  form: 'status',
});

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  currentContactExpert,
  updatingStatus,
});

const actions = {
  getContactExpert,
  onSubmit: changeStatus
};

const reduxConect = connect(selector, actions);

export { CurrentContactExpert };

export default compose(reduxConect, formConect)(withRouter(CurrentContactExpert));