import { combineReducers } from 'redux';
import { FormStateMap } from 'redux-form';
import { RouterState } from 'connected-react-router';
import { History } from 'history';

import form from './form/reducer';
import router from './router/reducer';
import expert, { IExpertState } from './expert/reducer';
import profile, { IPofileState } from './profile/reducer';
import users, { IUsersState } from './users/reducer';
import contactExperts, { IContactState } from './contactExpert/reducer';
import manualRequests, { IManualReqState } from './manualRequests/reducer';
import stat, { IStatState } from './stat/reducer';

export interface IRootState {
  form: FormStateMap;
  router: RouterState;
  expert: IExpertState;
  profile: IPofileState;
  users: IUsersState;
  contactExperts: IContactState;
  manualRequests: IManualReqState;
  stat: IStatState;
}

export interface IResponseData {
  data: any;
  status: number;
}

export interface IResponseError {
  error: {
    code: string;
    fields: object;
    type?: string;
    message?: string;
    description?: string;
    response: {
      text: string;
    }
  };
  status: number;
}

export type IResponse = IResponseData & IResponseError;

export default (history: History) =>
  combineReducers<IRootState>({
    form,
    router: router(history),
    expert,
    profile,
    users,
    contactExperts,
    manualRequests,
    stat,
  });