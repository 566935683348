import React, { useEffect } from 'react';
import { ROUTES } from '../../constants/routes';
import PaginationTableExperts from "../PaginationTableExperts";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

interface IProps {
    getList?(): any;

    editExpert?(id: string, data: object): void;

    experts?: {
        _id: string,
        expertId: string,
        profile: {
            firstName: string,
            photoUrl: string,
            videoUrl: string
        },
        therapy: {
            theme: string[],
            price: {
                online: number,
                offline: number
            }
        }
    }[];
}

const UserRequest: React.FC<IProps> = (props): JSX.Element => {
    const {
        getList = () => {},
        editExpert = () => {},
        experts = []
    } = props;

    const actions = {
        onChange: editExpert
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <PaginationTableExperts
                data={experts}
                route={ROUTES.EXPERTS}
                header='Психологи'
                actions={actions}
            />
            <Button variant='info' size='sm' style={{marginLeft: 'auto', marginRight: '100px', display: 'block'}}>
                <Link to={`${ROUTES.EXPERTS}/new`} style={{color: 'white'}}>
                    Додати спеціаліста
                </Link>
            </Button>
        </>
    );
};

export default UserRequest;
