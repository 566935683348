import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { STATUSES } from '../../constants/statusTypes';

interface IProps {
    data: object[];
    header: string;
    route: string;
    actions?: {
        onChange: (id: string, data: object) => void
    }
}

const PaginationTable: React.FC<IProps> = (props): JSX.Element => {
    const {data, header, route, actions} = props;

    function hideCheckboxFormatter(cell: any, row: any) {
        const onChange = () => actions?.onChange(row._id, {isHidden: !row.isHidden})

        return (
            <div className="custom-control custom-checkbox" key={row._id}>
                <input checked={row.isHidden} onChange={onChange} type="checkbox" className="custom-control-input"
                       id={row._id}/>
                <label className="custom-control-label" htmlFor={row._id}/>
            </div>
        )
    }

    function editButtonFormatter(cell: any, row: any) {
        return (
            <Button variant='info' size='sm'>
                <Link to={`${route}/${row.expertId}`}>
                    <FontAwesomeIcon icon='edit' color='white'/>
                </Link>
            </Button>
        );
    }

    function indexRowFormatter(cell: any, row: any, rowIndex: any) {
        return <div>{rowIndex + 1}</div>;
    }

    function linkFormatter(cell: any, row: any) {
        return <a href={`https://egobalance.com.ua/experts/${row.expertId}`} target='_blank'>{row.expertId}</a>
    }

    const columns = [
        {
            dataField: 'i',
            text: '#',
            isDummyField: true,
            formatter: indexRowFormatter,
        },
        {
            dataField: 'profile.firstName',
            text: 'Ім\'я',
        },
        {
            dataField: 'expertId',
            text: 'Сторінка профілю',
            formatter: linkFormatter
        },
        {
            dataField: '_id',
            isDummyField: true,
            text: 'Редагувати',
            formatter: editButtonFormatter
        },
        {
            dataField: 'profile.lastName',
            isDummyField: true,
            text: 'Приховати',
            formatter: hideCheckboxFormatter
        },
    ];

    const customTotal = (from: number, to: number, size: number) => (
        <span className='react-bootstrap-table-pagination-total ml-3'>
      Від {from} до {to} з {size} психологів
    </span>
    );

    const rowStyle = (row: any, rowIndex: number) => {
        if (rowIndex % 2 === 0) {
            return {
                backgroundColor: '#EEEEEE',
            };
        }
        return {
            backgroundColor: 'white',
        };
    };

    const options = {
        paginationSize: 5,
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'На початок',
        prePageText: 'Назад',
        nextPageText: 'Вперед',
        lastPageText: 'В кінець',
        nextPageTitle: 'Перша сторінка',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPage: 20,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: data.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
        <div style={{margin: '50px 100px'}}>
            <h1 className='my-3'>{header}</h1>

            <BootstrapTable
                keyField='_id'
                data={data}
                columns={columns}
                // @ts-ignore
                pagination={paginationFactory(options)}
                rowStyle={rowStyle}
                bordered={false}
            />
        </div>
    );
};

export default PaginationTable;
