import React, { useEffect } from 'react';
import { Container, Form, Col, Button, Card, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import FieldInput from '../Input';
import { STATUSES } from '../../constants/statusTypes';
import { currentStatus, sessionTypeTransform } from '../../utils';

export interface IProps {
  getManualRequest?: () => void;
  loading: boolean;
  error: object | null;
  currentManualRequest?: {
    _id: string,
    status: string,
    userId: string,
    createdAt: string,
    updatedAt?: string,
    userRequest: {
      type: string,
      manual: {
        request: string,
        specialistRequest?: string,
      }
    },
    age?: string,
    sex?: string,
    email: string,
    name: string,
    phone: string,
    connectType: string,
    telegramName?: string,
  };
  updatingStatus: boolean;
  onSubmit?(): void;
}

const CurrentManualRequest: React.FC<IProps & any> = (props: any): JSX.Element => {
  const {
    currentManualRequest,
    match,
    getManualRequest,
    handleSubmit,
    onSubmit,
    updatingStatus
  } = props;

  const submit = (values: any) => {
    const data: { _id: string, status: string } = {
      status: values.status,
      _id: match.params.id
    };

    onSubmit(data);
  };

  useEffect(() => {
    const currentId = match.params.id;
    getManualRequest && getManualRequest(currentId);
  }, []);

  const {
    status,
    createdAt,
    updatedAt,
    userRequest,
    age,
    sex,
    email,
    name,
    phone,
    connectType,
    telegramName
  } = currentManualRequest;
  const reqCurrentStatus = currentStatus(status);
  const backToTable = match.path.replace("/:id", "");

  return (
    <Container>
      <Row className='mt-4'>
        <Col>
          <Link to={backToTable ? backToTable : "/"}>
            <Button>Назад до таблиці</Button>
          </Link>
        </Col>
      </Row>
      <Card className='mt-4 p-3'>
        <Row>
          <Col>Створено: {moment(createdAt).format("DD/MM/YYYY HH:mm")}</Col>
        </Row>
        <Row>
          <Col>
            Оновлено:{" "}
            {updatedAt ? moment(updatedAt).format("DD/MM/YYYY HH:mm") : "N/A"}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            Статус:{" "}
            <span style={{ color: reqCurrentStatus && reqCurrentStatus.color }}>
              {reqCurrentStatus && reqCurrentStatus.title}
            </span>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Row className='mt-3'>
            <Col xs='auto' className='my-1'>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Field
                  type='select'
                  component={FieldInput}
                  name='status'
                  options={STATUSES}
                  defaultValue={status}
                />
              </Form.Group>
            </Col>
            <Col xs='auto' className='my-1'>
              <Button type='submit'>
                {updatingStatus ? "Йде зміна статусу..." : "Змінити статус"}
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <Row>
          <Col>
            <h4>Дані користувача:</h4>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <span className='bold'>Ім'я:</span> {name}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Email:</span> {email}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Телефон:</span> {phone}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Вік:</span> {age ? age : "N/A"}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Стать:</span> {sex ? sex : "N/A"}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Пріорітетний канал зв'язку:</span>{" "}
            {connectType}
          </Col>
        </Row>
        {
            connectType === 'telegram' && (
                <Row>
                  <Col>
                    <span className='bold'>Telegram username:</span>{" "}
                    {telegramName}
                  </Col>
                </Row>
            )
        }
        <Row className='mt-4'>
          <Col>
            <h4>Запит користувача:</h4>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <span className='bold'>Головний текст:</span>{" "}
            {userRequest && userRequest.manual.request}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Побажання:</span>{" "}
            {userRequest && (userRequest.manual.specialistRequest || "N/A")}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='bold'>Тип консультації:</span>{" "}
            {userRequest && sessionTypeTransform(userRequest.type)}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CurrentManualRequest;
