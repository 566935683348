import React from "react";
import Select, {IProps as SelectProps} from "../../Select";
import {Button, ListGroup} from "react-bootstrap";


interface IProps extends SelectProps {
    onDelete(index: number): void;
    items: any[]
}

export const SelectMapper: React.FC<IProps> = (props): JSX.Element => {
    const {title, options, items, onSelect, onDelete } = props;

    return (
        <div className='select-mapper'>
            <Select
                title={title}
                options={options}
                onSelect={onSelect}
            />

            <ListGroup>
                {items?.map((item, index) => {
                    return (
                        <ListGroup.Item key={index} className='d-sm-flex'>
                            <Button type="button" onClick={() => onDelete(index)}
                                    style={{marginRight: "10px"}}>
                                Видалити
                            </Button>
                            <span>{options.find((option) => option.value === item)?.title}</span>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}



