import { createStore, applyMiddleware, compose as simpleCompose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory, History } from 'history';
import { IApi } from '../api';

import apiMiddleware, { IAction } from './apiMiddleware';

import reducer, { IRootState } from './reducer';
import rootSaga from './sagas';

export const history = createBrowserHistory();

export default function configureStore(api: IApi, _history: History) {
  const compose =
    process.env.NODE_ENV === 'production'
      ? simpleCompose
      : (composeWithDevTools as typeof simpleCompose);

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore<IRootState, IAction, {}, {}>(
    reducer(_history),
    compose(
      applyMiddleware(
        apiMiddleware(api),
        routerMiddleware(_history),
        sagaMiddleware
      )
    )
  );

  sagaMiddleware.run(() => rootSaga(history, api));

  return store;
}
