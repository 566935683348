import { IApi } from '../../api';

export const prefix = 'manualRequests';

export const GET_ALL_USER_MANUAL_REQ_REQUEST = `${prefix}/GET_ALL_USER_MANUAL_REQ_REQUEST`;
export const GET_ALL_USER_MANUAL_REQ_SUCCESS = `${prefix}/GET_ALL_USER_MANUAL_REQ_SUCCESS`;
export const GET_ALL_USER_MANUAL_REQ_FAILURE = `${prefix}/GET_ALL_USER_MANUAL_REQ_FAILURE`;

export const getList = (limit?: number) => {
  return {
    type: [
      GET_ALL_USER_MANUAL_REQ_REQUEST,
      GET_ALL_USER_MANUAL_REQ_SUCCESS,
      GET_ALL_USER_MANUAL_REQ_FAILURE,
    ],
    promise: (api: IApi) => api.experts.getAllUserManualReq({ limit }),
  };
};

export const GET_MANUAL_REQ_REQUEST = `${prefix}/GET_MANUAL_REQ_REQUEST`;
export const GET_MANUAL_REQ_SUCCESS = `${prefix}/GET_MANUAL_REQ_SUCCESS`;
export const GET_MANUAL_REQ_FAILURE = `${prefix}/GET_MANUAL_REQ_FAILURE`;

export const getManualRequest = (reqId: string) => {
  return {
    type: [GET_MANUAL_REQ_REQUEST, GET_MANUAL_REQ_SUCCESS, GET_MANUAL_REQ_FAILURE],
    promise: (api: IApi) => api.experts.getManualRequest(reqId),
  };
};

export const CHANGE_USER_MANUAL_REQ_STATUS_REQUEST = `${prefix}/CHANGE_USER_MANUAL_REQ_STATUS_REQUEST`;
export const CHANGE_USER_MANUAL_REQ_STATUS_SUCCESS = `${prefix}/CHANGE_USER_MANUAL_REQ_STATUS_SUCCESS`;
export const CHANGE_USER_MANUAL_REQ_STATUS_FAILURE = `${prefix}/CHANGE_USER_MANUAL_REQ_STATUS_FAILURE`;

export const changeStatus = (
  data: {
    _id: string,
    status: string,
  }) => {
  return {
    type: [
      CHANGE_USER_MANUAL_REQ_STATUS_REQUEST,
      CHANGE_USER_MANUAL_REQ_STATUS_SUCCESS,
      CHANGE_USER_MANUAL_REQ_STATUS_FAILURE,
    ],
    promise: (api: IApi) => api.experts.changeManualStatus(data),
  };
};