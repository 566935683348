import { IApi } from '../../api';
import { INewExpertModel, IUpdatedExpertModel } from "../../models/Expert.model";
import { transformToMinPrefix } from "../../utils";

export const prefix = 'expert';

export const GET_ALL_EXPERTS_REQUEST = `${prefix}/GET_ALL_EXPERTS_REQUEST`;
export const GET_ALL_EXPERTS_SUCCESS = `${prefix}/GET_ALL_EXPERTS_SUCCESS`;
export const GET_ALL_EXPERTS_FAILURE = `${prefix}/GET_ALL_EXPERTS_FAILURE`;

export const getList = (limit?: number) => {
    return {
        type: [
            GET_ALL_EXPERTS_REQUEST,
            GET_ALL_EXPERTS_SUCCESS,
            GET_ALL_EXPERTS_FAILURE,
        ],
        promise: (api: IApi) => api.experts.list({limit}),
    };
};

export const GET_EXPERTS_BY_IDS_REQUEST = `${prefix}/GET_EXPERTS_BY_IDS_REQUEST`;
export const GET_EXPERTS_BY_IDS_SUCCESS = `${prefix}/GET_EXPERTS_BY_IDS_SUCCESS`;
export const GET_EXPERTS_BY_IDS_FAILURE = `${prefix}/GET_EXPERTS_BY_IDS_FAILURE`;

export const getExpertsByIds = (
    data: any
) => {
    return {
        type: [
            GET_EXPERTS_BY_IDS_REQUEST,
            GET_EXPERTS_BY_IDS_SUCCESS,
            GET_EXPERTS_BY_IDS_FAILURE,
        ],
        promise: (api: IApi) => api.experts.listByIds({data}),
    };
};

export const GET_EXPERT_REQUEST = `${prefix}/GET_EXPERT_REQUEST`;
export const GET_EXPERT_SUCCESS = `${prefix}/GET_EXPERT_SUCCESS`;
export const GET_EXPERT_FAILURE = `${prefix}/GET_EXPERT_FAILURE`;

export const getExpert = (expertId: string) => {
    return {
        type: [GET_EXPERT_REQUEST, GET_EXPERT_SUCCESS, GET_EXPERT_FAILURE],
        promise: (api: IApi) => api.experts.getExpert(expertId),
    };
};

export const POST_CREATE_EXPERT_REQUEST = `${prefix}/POST_CREATE_EXPERT_REQUEST`;
export const POST_CREATE_EXPERT_SUCCESS = `${prefix}/POST_CREATE_EXPERT_SUCCESS`;
export const POST_CREATE_EXPERT_FAILURE = `${prefix}/POST_CREATE_EXPERT_FAILURE`;

export const createExpert = (expert: INewExpertModel) => {
    const {
        profile: {videoUrl, photoUrl, ...profileData},
        experience: {diplomas, ...experienceData},
        ...expertData
    } = expert;

    const newExpert = {
        profile: {
            videoUrl: videoUrl?.name,
            photoUrl: transformToMinPrefix(photoUrl.name),
            ...profileData
        },
        experience: {
            diplomas: diplomas.map((diploma) => transformToMinPrefix(diploma.name)),
            ...experienceData
        },
        ...expertData
    }

    return {
        type: [POST_CREATE_EXPERT_REQUEST, POST_CREATE_EXPERT_SUCCESS, POST_CREATE_EXPERT_FAILURE],
        promise: (api: IApi) => api.experts.createExpert(newExpert, {photoUrl, videoUrl, diplomas}),
    }
}

export const PATCH_EXPERT_REQUEST = `${prefix}/PATCH_TOGGLE_HIDE_EXPERT_REQUEST`
export const PATCH_EXPERT_SUCCESS = `${prefix}/PATCH_TOGGLE_HIDE_EXPERT_SUCCESS`
export const PATCH_EXPERT_FAILURE = `${prefix}/PATCH_TOGGLE_HIDE_EXPERT_FAILURE`

export const toggleHideExpert = (id: string, data: object) => {
    return {
        type: [
            PATCH_EXPERT_REQUEST,
            PATCH_EXPERT_SUCCESS,
            PATCH_EXPERT_FAILURE
        ],
        promise: (api: IApi) => api.experts.editExpert(id, data),
    }
}


export const editExpert = (id: string, expert: IUpdatedExpertModel) => {
    const updatedExpert = {...expert};
    const files: { diplomas: File[], videoUrl?: File, photoUrl?: File } = { diplomas: []};

    if (updatedExpert.profile.videoUrl instanceof File) {
        files.videoUrl = updatedExpert.profile.videoUrl
        updatedExpert.profile.videoUrl = updatedExpert.profile.videoUrl.name
    }

    if (updatedExpert.profile.photoUrl instanceof File) {
        files.photoUrl = updatedExpert.profile.photoUrl

        updatedExpert.profile.photoUrl = transformToMinPrefix(updatedExpert.profile.photoUrl.name);
    }


    updatedExpert.experience.diplomas.forEach((diploma, index) => {
        if (diploma instanceof File) {
            files.diplomas = [...files.diplomas, diploma]
            updatedExpert.experience.diplomas[index] = transformToMinPrefix(diploma.name)
        }
    })

    return {
        type: [
            PATCH_EXPERT_REQUEST,
            PATCH_EXPERT_SUCCESS,
            PATCH_EXPERT_FAILURE
        ],
        promise: (api: IApi) => api.experts.editExpert(id, updatedExpert, files),
    }
}
