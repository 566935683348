import Base from './base';

export default class Experts extends Base {
    list(query?: object) {
        return this.apiClient.get('admin/expert', query);
    };

    listByIds(data: object) {
        return this.apiClient.get('expert-byIds', data);
    };

    getExpert(expertId: string) {
        return this.apiClient.get(`expert/${expertId}`);
    };

    getAllContactExperts(query?: object) {
        return this.apiClient.get('expert/connectExpert', query);
    };

    changeStatus(data: object[]) {
        return this.apiClient.patch('expert/updateStatus', data)
    };

    getAllUserManualReq(query?: object) {
        return this.apiClient.get('expert/allManualRequests', query);
    };

    changeManualStatus(data: object) {
        return this.apiClient.patch('expert/updateManualStatus', data)
    };

    getManualRequest(reqId: string) {
        return this.apiClient.get(`expert/manualRequest/${reqId}`);
    };

    getContactExpert(contactId: string) {
        return this.apiClient.get(`expert/connectExpert/${contactId}`);
    }

    createExpert(expert: any, files: { photoUrl: File, videoUrl: File, diplomas: File[] }) {
        const req = this.apiClient._customRequest({
            url: 'expert/create',
            method: 'post',
        });

        req.field('expert', JSON.stringify(expert))

        files.diplomas.forEach((file) => {
            req.attach(`diplomas[]`, file)
        })

        if (files?.videoUrl) {
            req.attach('videoUrl', files.videoUrl)
        }

        return req
            .accept('application/json')
            .attach('photoUrl', files.photoUrl)
            .catch((error: Error) => {
                throw new Error(error.message)
            })
    }


    editExpert(id: string, updatedFields: object, files?: { photoUrl?: File, videoUrl?: File, diplomas: File[] }) {

        if (!files || !Object.values(files).length) {
            return this.apiClient.patch(`admin/expert/${id}`, {updatedFields})
        }

        const req = this.apiClient._customRequest({
            url: `admin/expert/${id}`,
            method: 'patch'
        })

        req.field('expert', JSON.stringify(updatedFields))

        if (files?.videoUrl) {
            req.attach('videoUrl', files.videoUrl)
        }

        if (files?.photoUrl) {
            req.attach('photoUrl', files.photoUrl)
        }

        if (files.diplomas.length) {
            files.diplomas.forEach(file => {
                req.attach(`diplomas[]`, file)
            })
        }

        return req
            .accept('application/json')
            .catch((error: Error) => {
                throw new Error(error.message)
            })
    }
}
