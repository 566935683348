import React from "react";
import {DropdownButton, Dropdown, DropdownProps} from "react-bootstrap";

export interface IProps extends DropdownProps{
    title: string,
    options: { value: string, title: string }[],
    onSelect(eventKey: any, event?: Object): void,
}


const Select: React.FC<IProps> = (props): JSX.Element => {
    const {title, options, onSelect} = props;

    return (
        <>
            <DropdownButton  id="dropdown-basic-button" title={title}>
                {options.map(({title, value}) => {
                    return <Dropdown.Item onClick={() =>  onSelect(value, title)} key={value} >{title}</Dropdown.Item>
                })}
            </DropdownButton>
        </>
    )
};


export default Select;
