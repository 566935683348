import { IApi } from '../../api';

export const prefix = 'stat';

export const GET_STAT_REQUEST = `${prefix}/GET_STAT_REQUEST`;
export const GET_STAT_SUCCESS = `${prefix}/GET_STAT_SUCCESS`;
export const GET_STAT_FAILURE = `${prefix}/GET_STAT_FAILURE`;

export const getStat = () => {
  return {
    type: [
      GET_STAT_REQUEST,
      GET_STAT_SUCCESS,
      GET_STAT_FAILURE,
    ],
    promise: (api: IApi) => api.stat.getStat(),
  };
};
