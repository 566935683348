import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { STATUSES } from '../../constants/statusTypes';

interface IProps {
  data: object[];
  header: string;
  route: string;
}

const PaginationTable: React.FC<IProps> = (props): JSX.Element => {
  const { data, header, route } = props;

  function dateFormatterCreate(cell: any, row: any) {
    return <>{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</>;
  }

  function dateFormatterUpdate(cell:any, row:any) {
    return (
      <>
        {row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY HH:mm') : 'N/A'}
      </>
    );
  }

  function editButtonFormatter(cell: any, row: any) {
    return (
      <Button variant='info' size='sm'>
        <Link to={`${route}/${row._id}`}>
          <FontAwesomeIcon icon='edit' color='white' />
        </Link>
      </Button>
    );
  }

  function statusFormatter(cell: any, row: any) {
    const currentStatus: {
      title: string;
      color: string;
      value: string;
    } = STATUSES.filter((stat) => stat.value === row.status)[0];

    return (
      <div className='text-nowrap' style={{ color: currentStatus ? currentStatus.color : 'grey'}}>
        {currentStatus ? currentStatus.title : 'помилка статусу'}
      </div>
    );
  }

  function indexRowFormatter(cell: any, row: any, rowIndex: any) {
    return <div>{rowIndex + 1}</div>;
  }

  const columns = [
    {
      dataField: 'i',
      text: '#',
      isDummyField: true,
      formatter: indexRowFormatter,
    },
    {
      dataField: 'createdAt',
      text: 'Створено',
      formatter: dateFormatterCreate,
    },
    {
      dataField: 'updatedAt',
      text: 'Змінено',
      formatter: dateFormatterUpdate,
    },
    {
      dataField: 'status',
      text: 'Статус',
      formatter: statusFormatter,
    },
    {
      dataField: 'name',
      text: 'Ім\'я',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'phone',
      text: 'Телефон',
    },
    {
      dataField: '_id',
      isDummyField: true,
      text: 'Детальніше',
      formatter: editButtonFormatter,
      style: () => ({ textAlign: 'center' as const }),
    },
  ];

  const customTotal = (from: number, to: number, size: number) => (
    <span className='react-bootstrap-table-pagination-total ml-3'>
      Від {from} до {to} з {size} запитів
    </span>
  );

  const rowStyle = (row: any, rowIndex: number) => {
    if (rowIndex % 2 === 0) {
      return {
        backgroundColor: '#EEEEEE',
      };
    }
    return {
      backgroundColor: 'white',
    };
  };

  const options = {
    paginationSize: 5,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'На початок',
    prePageText: 'Назад',
    nextPageText: 'Вперед',
    lastPageText: 'В кінець',
    nextPageTitle: 'Перша сторінка',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '20',
        value: 20,
      },
      {
        text: 'All',
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <div style={{ margin: '50px 100px' }}>
      <h1 className='my-3'>{header}</h1>

      <BootstrapTable
        keyField='id'
        data={data}
        columns={columns}
        // @ts-ignore
        pagination={paginationFactory(options)}
        rowStyle={rowStyle}
        bordered={false}
      />
    </div>
  );
};

export default PaginationTable;
