import { prefix } from './actions';
import { IRootState } from '../reducer';

export const loading = (state: IRootState) => state[prefix].loading;

export const error = (state: IRootState) => state[prefix].error;

export const experts = (state: IRootState) => state[prefix].experts;

export const expert = (state: IRootState) => state[prefix].expert;
