import Base from './base';

export default class Users extends Base {
  list(query?: object) {
    return this.apiClient.get('user', query);
  }

  getUser(id: string) {
    return this.apiClient.get(`user/${id}`);
  }

  create(data: object) {
    return this.apiClient.post('user', data);
  }

  connectExpert(data: object) {
    return this.apiClient.post('expert/connect', data);
  }

  question(data: object) {
    return this.apiClient.post('question', data);
  }
}
